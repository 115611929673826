window.onerror = function(message, source, lineno, colno, error) {
	if (message.includes('Minified React error')) {
	  return true;
	}
	return !false;
};

import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
// Router
import { BrowserRouter as Router } from 'react-router-dom';
// Redux
import { Provider } from 'react-redux';
import { IInitialState } from './dead_____reducers/index';
import { config } from '../../config';

import App from './components/App';
import configureStore from '../redux/configureStore';
import logger from '../logging/log';
import serviceWorkerRegistration from './../../serviceWorkerRegistration';
import pako from 'pako';

declare global {
	interface Window {
		__PRELOADED_STATE__?: IInitialState;
	}
}

declare global {
	interface NodeModule {
		hot?: IHot;
	}
}

interface IHot {
	accept
}

function isValidJSON(str) {
	try {
		return JSON.parse(str);
	} catch (e) {
		return false;
	}
}
const { ENV, PREFIX_URL } = config;

let preloadedState = window.__PRELOADED_STATE__;
if (preloadedState !== undefined && preloadedState) {
	if( !isValidJSON(preloadedState) ) {
		let tempState = atob(preloadedState as unknown as string);
		const uint8Array = new Uint8Array(tempState.split('').map(char => char.charCodeAt(0)));
		tempState = pako.ungzip(uint8Array, { to: 'string' });
		preloadedState = JSON.parse(tempState);
	}
	(preloadedState as IInitialState).iscsr = true;
}

delete window.__PRELOADED_STATE__;

const container = document.getElementById('app')!;

if (ENV === 'development' || ENV === 'qa') {
	const root = createRoot(container);
	const store = configureStore(preloadedState);
	root.render(
		<Provider store={store}>
			<Router basename={PREFIX_URL}>
				<App />
			</Router>
		</Provider>
	);
}

// add "const root" to be able to rerender.
if (ENV === 'production') {
	const store = configureStore(preloadedState);

	try {
		hydrateRoot(container,
			<Provider store={store}>
				<Router basename={PREFIX_URL}>
					<App />
				</Router>
			</Provider>,
			{ 
				onRecoverableError: (error) => {},
	
			},
		);
	} catch (error) {}
	
}

// Use root.render to update later the app
/* root.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>
); */

if ((ENV) && (ENV === 'production')) {
	//serviceWorkerRegistration();
}

//serviceWorkerRegistration();

if (module.hot) {
	module.hot.accept();
}
