import { PAGE_TYPES, PRIMARY_COMPONENTS, SECONDARY_COMPONENTS, apiUrl_sectionHeader, SUPER_USER_VALUE, ENV, COMMON_CONST_PARAMS } from '@fronend/common/constants';
import { getMSID, getPageData, getSSRComponentsURLs, getcmstype, getURLHref } from '@fronend/common/utils';
import { hashSync, setRandomFallback } from 'm360-react-components';
import logger from '../../../logging/log';
//const fetch = require('node-fetch'); //for windows local running by GS


export function fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, pageType, events, res, loading) {
	return (dispatch, getState) => {
		if (shouldFetchHomeData(getState(), params, query, headers, hostname, req, pageType, events, res, loading)) {
			return dispatch(fetchHomeData(getState(), params, query, cookies, headers, hostname, req, pageType, events, res, loading));
		} else {
			return Promise.resolve([]);
		}
	};
}

function shouldFetchHomeData(state, params, query, headers, hostname, req, pageType, events, res, loading) {
	// logger.info(state, params, query, headers, hostname, req, pageType, events);
	return true;
	// if (
	// 	typeof state.home == 'undefed' 
	// ) {
	// 	return true;
	// } else {
	// 	return false;
	// }
}

var endpoint_i = 0;
var ssrComponentsURLs = [];
function fetchHomeData(state, params, query, cookies, headers, hostname, req, pageType, events, res, loading) {
	// logger.info(state, query, cookies, headers, req);
	return (dispatch) => {

		let msid = null;
		if (params && Object.keys(params).length) {
			msid = getMSID(params[0]);
		}

		let pageJson = null;
		let promises = [];
		let promisesHolder = {};
		let promisesHolderMap = [];
		let Promise1 = getPageData(hostname, pageType, req.url);
		return Promise1.then((i_pageJson) => {
			let clientHostId = i_pageJson.website.denmarkObject.hostId;

			let siteLanguage = "en";
			if (i_pageJson.website.websiteResouces.siteLanguage) {
				siteLanguage = i_pageJson.website.websiteResouces.siteLanguage;
			}

			let jss = JSON.parse(i_pageJson.website.theme.page.json);
			URLDrivenFlow(jss, msid, pageType, params && Object.keys(params).length && params[0]);
			i_pageJson.website.theme.page.json = JSON.stringify(jss);
			pageJson = i_pageJson;

			ssrComponentsURLs = getSSRComponentsURLs(i_pageJson, loading, query, req, pageType, clientHostId);
			if (query !== undefined && query && Object.keys(query).length > 0 && query.ssr_mode && (query.ssr_mode == 0 || query.ssr_mode == '0')) {
				ssrComponentsURLs = [];
			}

			for (let i_index in ssrComponentsURLs) {
				let i_url = ssrComponentsURLs[i_index];
				if (ENV && ENV.REACT_APP_MODE && ENV.REACT_APP_MODE == 'production' && (i_url.includes('cdsfeed-ext.indiatimes.com') || i_url.includes("nprelease.indiatimes.com"))) {
					i_url = i_url.replace("https://cdsfeed-ext.indiatimes.com", "https://vsp1cdsfeed-ext.indiatimes.com");
					i_url = i_url.replace("https://nprelease.indiatimes.com", "https://vsp1cdsfeed-ext.indiatimes.com");
					ssrComponentsURLs[i_index] = i_url.replace("http://cdsfeed-ext.indiatimes.com", "http://vsp1cdsfeed-ext.indiatimes.com");
				}

				if (ENV && ENV.REACT_APP_MODE && i_url.includes('/admin/api/ext/newsroomapi')) {
					i_url = i_url.split("newsroomapi")[1];
					ssrComponentsURLs[i_index] = ENV.REACT_APP_NEWSROOM_API + "/newsroomApi" + i_url;
				}

				if (ENV && ENV.REACT_APP_MODE && i_url.includes('/pagebuilder/newsroomApi')) {
					i_url = i_url.split("/pagebuilder/newsroomApi")[1];
					ssrComponentsURLs[i_index] = ENV.REACT_APP_NEWSROOM_API + "/newsroomApi" + i_url;
				}
			}
			// logger.info(ssrComponentsURLs.filter((x, i, a) => a.indexOf(x) == i), "ssrComponentsURLs");

			setRandomFallback((len) => {
				const buf = new Uint8Array(len);
				return buf.map(() => Math.floor(256));
			});

			logger.info("***ssrComponentURL final endpoints...");

			ssrComponentsURLs.forEach(ssrComponentURL => {

				//replace moderatee in rendering...
				// console.log("***ssrComponentURL before: " + ssrComponentURL);
				ssrComponentURL = ssrComponentURL.replace("/moderate/", "/");
				// console.log("***ssrComponentURL after: " + ssrComponentURL);

				if (ssrComponentURL.includes('getsiteResources')) {
					if (!ssrComponentURL.includes("rendering/getsiteResources")) {
						ssrComponentURL = ssrComponentURL.replace("getsiteResources", "rendering/getsiteResources");
					}
					let headerString = `?` + ssrComponentURL.split("?")[1];
					headerString = hashSync(headerString, 6);
					const customHeaders = {
						'Content-Type': 'application/json',
						'p-nonce': headerString
					};
					const pr = fetch(ssrComponentURL, {
						method: 'GET',
						headers: customHeaders
					}).then(r => r.json())
						.catch(error => {
							logger.error(error, `Fetch failed for URL: ${ssrComponentURL}`);
							return null; // Return null or any placeholder to maintain the sequence
						});
						promisesHolderMap.push(ssrComponentURL);
						if( !promisesHolder[ssrComponentURL] ) {
							promisesHolder[ssrComponentURL] = pr;
						}
					
					//promises.push(pr);
					return;
				}

				logger.info(ssrComponentURL);

				if (ssrComponentURL.includes('key=header') && !ssrComponentURL.includes('toggle=true')) {
					const fetch1 = require('sync-fetch');

					if (msid && msid.indexOf("main.") == -1) {
						const url = new URL(ssrComponentURL);
						const params = new URLSearchParams(url.search);
						const hostId = params.get('hostId');
						const domain = url.hostname;

						if (pageType === PAGE_TYPES.ArticleList || pageType === PAGE_TYPES.PhotoList || pageType === PAGE_TYPES.VideoList
							|| pageType === PAGE_TYPES.WebstoryList || pageType === PAGE_TYPES.PodcastList || pageType === PAGE_TYPES.FAQList
							|| pageType === PAGE_TYPES.QuizList || pageType === PAGE_TYPES.SurveyList) {
							// const url = apiUrl_sectionHeader.replace("{DOMAIN}", domain).replace("{hostId}", hostId).replace("{msid}", msid);

							// let response = [];
							// let pr = null;
							// let statusCode = 0;
							// try {
							// 	pr = fetch1(url, {
							// 		method: 'GET',
							// 		headers: {
							// 			'ak': SUPER_USER_VALUE
							// 		}
							// 	});
							// 	statusCode = pr ? pr.status : 0;
							// 	response = pr ? pr.json() : null;
							// } catch (error) {
							// 	logger.error(error, `Fetch failed for URL: ${url}`);
							// }
							// if (statusCode === 200 && response && Array.isArray(response) && response.length > 0) {
							// 	response.unshift({ secid: '123', path: '/', type: 1 });
							// 	promises.push(Promise.resolve(response));
							// 	return;
							// }
						} else if (pageType === PAGE_TYPES.ArticleShow || pageType === PAGE_TYPES.PhotoShow || pageType === PAGE_TYPES.VideoShow
							|| pageType === PAGE_TYPES.AmpArticleShow || pageType === PAGE_TYPES.AmpPhotoShow || pageType === PAGE_TYPES.AmpVideoShow
							|| pageType === PAGE_TYPES.QuizShow || pageType === PAGE_TYPES.SurveyShow) {
							const articleShowUrl = ssrComponentsURLs.filter((url) => url.includes('cds-feed-ext/show'));
							if (articleShowUrl.length > 0) {
								let response = {};
								try {
									response = fetch1(articleShowUrl, {
										method: 'GET',
										headers: {
											'ak': SUPER_USER_VALUE
										}
									});
								} catch (error) {
									logger.error(error, `Fetch failed for URL: ${articleShowUrl}`);
								}
								const as = response ? response.json() : null;
								// if (as !== undefined && as && as !== '' && as.it !== undefined) {
								// 	const folderId = as.it.folderId;
								// 	let url = apiUrl_sectionHeader.replace("{DOMAIN}", domain).replace("{hostId}", hostId).replace("{msid}", folderId);

								// 	let response = [];
								// 	let pr = {};
								// 	let statusCode = 0;
								// 	try {
								// 		pr = fetch1(url, {
								// 			method: 'GET',
								// 			headers: {
								// 				'ak': SUPER_USER_VALUE
								// 			}
								// 		});

								// 		statusCode = pr ? pr.status : 0;
								// 		response = pr ? pr.json() : null;
								// 	} catch (error) {
								// 		logger.error(error, `Fetch failed for URL: ${url}`);
								// 	}
								// 	if (statusCode === 200 && response && Array.isArray(response) && response.length > 0) {
								// 		response.unshift({ secid: '123', path: '/', type: 1 });
								// 		promises.push(Promise.resolve(response));
								// 		return;
								// 	}
								// }
							}
						}
					}
				}
				if (ssrComponentURL.includes('cds-feed-ext')) {
					const pr = fetch(ssrComponentURL, {
						method: 'GET',
						headers: {
							'ak': SUPER_USER_VALUE
						}
					}).then(r => r.json())
						.catch(error => {
							logger.error(error, `Fetch failed for URL: ${ssrComponentURL}`);
							return null; // Return null or any placeholder to maintain the sequence
						});
						promisesHolderMap.push(ssrComponentURL);
						if( !promisesHolder[ssrComponentURL] ) {
							promisesHolder[ssrComponentURL] = pr;
						}
				} else {  //universal else
					const pr = fetch(ssrComponentURL).then(r => r.json())
						.catch(error => {
							logger.error(error, `Fetch failed for URL: ${ssrComponentURL}`);
							return null; // Return null or any placeholder to maintain the sequence
						});
						promisesHolderMap.push(ssrComponentURL);
						if( !promisesHolder[ssrComponentURL] ) {
							promisesHolder[ssrComponentURL] = pr;
						}
				}
			});


			return Promise.all(Object.values(promisesHolder))
				.then(
					(newData) => {
						let data = [];
						promisesHolderMap.forEach( (su, ii) => {
							data[ii] = newData[Object.keys(promisesHolder).indexOf(su)];

							// console.log("susususu", su, data[ii]);
							if( su.includes("/show/") || su.includes("primecase") ) {
								// console.log("showshowshowshowshowshowshowshow111", data[ii]);
								if( [ PAGE_TYPES.ArticleShow, PAGE_TYPES.AmpArticleShow, PAGE_TYPES.AmpVideoShow, PAGE_TYPES.PhotoShow, PAGE_TYPES.AmpPhotoShow, PAGE_TYPES.VideoShow, PAGE_TYPES.Podcast, PAGE_TYPES.QuizShow, PAGE_TYPES.SurveyShow ].includes(pageType) ) {
									pageJson.website.isPrime = findPrime(data[ii]);
									// console.log("showshowshowshowshowshowshowshow", pageJson.website.isPrime);
								}
							}
						} );
					
						//cookies
						// topicRobots
						// console.log("cookies for topic : ", cookies);

						const setCookieHeader = res.getHeader('Set-Cookie');
						// console.log("setCookieHeader : "  , setCookieHeader);

						const topicCookie = cookies.topicRobots;
						// console.log("topicCookie : ", topicCookie);

						if( setCookieHeader ) {
							// console.log("inside setCookieHeader");

							let topicRobotValue = "";
							if (Array.isArray(setCookieHeader)) {

								// console.log("setCookieHeader array...");
								const topicRobotCookie = setCookieHeader.find(cookie => cookie.startsWith('topicRobot='));
								if (topicRobotCookie) {
									topicRobotValue = topicRobotCookie.split(';')[0].split('=')[1]; // Extract the value
								}
							} else if (typeof setCookieHeader === 'string') {
								// console.log("setCookieHeader string...");
								if (setCookieHeader.indexOf("topicRobots=1") >= 0)
									{
									topicRobotValue = 1;
									
									// topicRobotValue = +setCookieHeader.split(';')[0].split('=')[1]; // Extract the value
									// console.log("topicRobotValue final :" , topicRobotValue);
								} 
								else {
									// console.log("setting topicRobotValue to 0");
									topicRobotValue = 0;
								}
							}
							// console.log("topicRobotValue :  " , topicRobotValue);
							pageJson.website.cookiesTopicRobots = topicRobotValue;
						}
						else if(topicCookie) {
							// console.log("inside topicCookie");
							pageJson.website.cookiesTopicRobots = topicCookie;
						}
						

						// console.log("pageJson.website: " , pageJson.website);


						endpoint_i = 0;
						let jss = JSON.parse(pageJson.website.theme.page.json);
						fillDataRecursive(jss, data, req, res, loading, pageType, siteLanguage, clientHostId);
						pageJson.website.theme.page.json = JSON.stringify(jss);
						dispatch(fetchHomeDataSuccess(pageJson, events.SUCCESS));
					},
					(error) => {
						console.log(error);
						logger.info(error, "error");
						dispatch(fetchHomeDataFailure(error, events.FAILURE));
					}
				)
				.catch(function (error) {
					console.log(error);
					logger.info(error, "error");
					dispatch(fetchHomeDataFailure(error));
				});

		})
			.catch(function (error) {
				console.log(error);
				logger.error("getPageTemplateAPIerror : " + error);
				res.redirect(302, "/page-not-found");
				dispatch(fetchHomeDataFailure(error));
			});
	};
}

function fillDataRecursive(jss, data, req, res, loading, pageType, siteLanguage, cId) {
	jss.map((component, i) => {
		if (siteLanguage && siteLanguage != "en") {
			component.lang = siteLanguage;
		}

		if (component.attributes && component.attributes.ENDPOINTS) {

			for ( let const_key of Object.keys(COMMON_CONST_PARAMS) ) {
				component.attributes[const_key] = eval(COMMON_CONST_PARAMS[const_key]);
			}

			component.attributes.ENDPOINTS.forEach(endpoint => {
				if (endpoint.isSSR) {
					//logger.info("endpoint :-", endpoint);
					if (typeof endpoint.primary !== 'undefined' && endpoint.primary) {
						if (pageType !== PAGE_TYPES.ErrorPage && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].error !== undefined && data[endpoint_i].error) {
							logger.info("Redirecting to error page due to wrong msid in pagetype : " + pageType);
							res.redirect(302, "/page-not-found");
						}
						// Article Show + Static
						else if (pageType !== PAGE_TYPES.ErrorPage && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].it !== undefined &&
							data[endpoint_i].it.tn !== undefined && data[endpoint_i].it.tn && data[endpoint_i].it.tn !== getcmstype(pageType)) {
							logger.info("Redirecting to error page due to wrong msid in pagetype :: " + pageType);
							res.redirect(302, "/page-not-found");
						}
						// Video Show + Podcast
						else if ((pageType == 'VideoShow' || pageType == 'Podcast') && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].items !== undefined && Object.keys(data[endpoint_i].items).length > 0 &&
							data[endpoint_i].items[0] !== undefined && data[endpoint_i].items[0].tn !== undefined && data[endpoint_i].items[0].tn && data[endpoint_i].items[0].tn !== getcmstype(pageType)) {
							logger.info("Redirecting to error page due to wrong msid in pagetype ::: " + pageType);
							res.redirect(302, "/page-not-found");
						}
						// Not for Topic, Tag, Error, Author, Amp
					}

					if (data[endpoint_i] !== undefined && data[endpoint_i] && !(data[endpoint_i].status !== undefined && data[endpoint_i].status !== 200)) {

						if (typeof endpoint.loading !== "undefined" && endpoint.loading && endpoint.loading == "LAZY") { // check for "SEO_URL" endpoint.

							if (loading && loading == "EAGER") { // check for loading = EAGER => listing api called.

								// Photo Show + Listing Page
								if (typeof endpoint.primary !== 'undefined' && endpoint.primary) {
									// Photo Show
									if ((pageType == PAGE_TYPES.PhotoShow || pageType == PAGE_TYPES.AmpPhotoShow || pageType == PAGE_TYPES.PhotoShowPreview || pageType == PAGE_TYPES.AmpPhotoShowPreview ) && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].items !== undefined && Object.keys(data[endpoint_i].items).length > 0 &&
										data[endpoint_i].items[0] !== undefined && data[endpoint_i].items[0].tn !== undefined && data[endpoint_i].items[0].tn && data[endpoint_i].items[0].tn !== getcmstype(pageType)) {
										logger.info("Redirecting to error page due to wrong msid in pagetype :::: " + pageType);
										res.redirect(302, "/page-not-found");
									}
									else if ((pageType == PAGE_TYPES.VideoShow || pageType == PAGE_TYPES.AmpVideoShow) && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].items !== undefined && Object.keys(data[endpoint_i].items).length > 0 &&
										data[endpoint_i].items[0] !== undefined && data[endpoint_i].items[0].tn !== undefined && data[endpoint_i].items[0].tn && data[endpoint_i].items[0].tn !== getcmstype(pageType)) {
										logger.info("Redirecting to error page due to wrong msid in pagetype ::::: " + pageType);
										res.redirect(302, "/page-not-found");
									}
									// Listing Page
									else if (pageType !== PAGE_TYPES.ErrorPage && data[endpoint_i] !== undefined && data[endpoint_i] && data[endpoint_i].items !== undefined && Object.keys(data[endpoint_i].items).length > 0 &&
										data[endpoint_i].items[0] !== undefined && data[endpoint_i].items[0].tn !== undefined && data[endpoint_i].items[0].tn && data[endpoint_i].items[0].tn !== getcmstype(pageType) && data[endpoint_i].items[0].tn !== 'SECTION') {
										logger.info("Redirecting to error page due to wrong msid in pagetype :::::: " + pageType);
										res.redirect(302, "/page-not-found");
									}
								}
								endpoint.SSRDataObj = data[endpoint_i];
							} else { endpoint_i--; }
						} else if(typeof endpoint.queryParams !== "undefined" && endpoint.queryParams && endpoint.queryParams.apiType && 
							(endpoint.queryParams.apiType == 'nav' || endpoint.queryParams.apiType == "sh") && 
							(pageType == PAGE_TYPES.AuthorDetail || pageType == PAGE_TYPES.TopicPage || pageType == PAGE_TYPES.TagPage || pageType == PAGE_TYPES.ErrorPage) ) {
								endpoint_i--;
						} else {
							endpoint.SSRDataObj = data[endpoint_i];
						}
					} else {
						logger.info("data[endpoint_i] is undefined or data[endpoint_i].status !== 200 for endpoint_i :: " + endpoint_i);
					}
					endpoint_i++;
				}
			});
		}
		if (component.components !== undefined && component.components) {
			fillDataRecursive(component.components, data, req, res, loading, pageType, siteLanguage, cId);
		}
	});
}

function findPrime(obj) {
    // Check if the current object has the primeId key
    if (obj && typeof obj === 'object') {
        if (obj.hasOwnProperty('primeId')) {
			if( obj.hasOwnProperty('tn') && [ 'ARTICLE', 'MEDIAAUDIO', 'MEDIAVIDEO', 'PHOTOGALLERYSLIDESHOWSECTION', 'QUIZ', 'SURVEY' ].includes(obj['tn']) ) {
				if(obj.primeId && obj.primeId > 0) {
					return true;
				}
			}
        }
        // Recursively check each key in the object
        for (const key in obj) {
            if (obj && obj.hasOwnProperty(key) && obj[key]) {
                const result = findPrime(obj[key]);
                if (result) {
                    return result;
                }
            }
        }
    }
    // Return undefined if primeId is not found
    return false;
}


function fetchHomeDataFailure(error, event) {
	return {
		type: event,
		payload: error.message,
	};
}

function fetchHomeDataSuccess(data, event) {
	return {
		type: event,
		meta: {
			receivedAt: Date.now(),
		},
		payload: data,
	};
}

export const URLDrivenFlow = (obj, msid, pageType, params) => {
	if (msid && msid.indexOf("main.") == -1) {
		const processObject = (object) => {
			if (object == null) {
				return;
			}
			if (object.type) {
				if (PRIMARY_COMPONENTS.includes(object.type) || SECONDARY_COMPONENTS.includes(object.type)) {
					let skip = false;
					if (!params && typeof window !== 'undefined' && undefined !== window && window) {
						params = getURLHref();
					}
					if (params.includes('/topics/') || params.includes('/tags/') || params.includes('/author/') || pageType == PAGE_TYPES.ErrorPage) {
						if (object.type === 'Bread Crumbs' || object.type === 'BreadCrumbs Two' || object.type === 'BreadCrumbs Three') {
							skip = true;
						}
					}

					if (typeof object.attributes.mappings === 'object' &&
						object.attributes.mappings.type === 'pathquery' && !skip) {
						eval(object.attributes.mappings.value);
					}
				}
			}

			// Iterate through object properties
			for (let key in object) {
				if (object.hasOwnProperty(key)) {
					if (typeof object[key] === 'object') {
						processObject(object[key]);
					}
				}
			}
		};

		// Start processing the object
		processObject(obj);
	}
};

